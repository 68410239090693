import { ClaimEditValues, SourceType } from 'api/endpoints';
import { EditClaimHook } from 'pages/SingleClaimPage/sections/SingleClaimOverview/hook/useEditClaim';
import { useMemo, useState } from 'react';
import { formatCurrency } from 'utils/formatNumber';
import { downloadClaimAttachment } from 'utils/uploader';
import { HeaderItem } from './components/HeaderItem';

import { ClaimAttachmentDocumentType } from 'api/endpoints/claimAttachments';
import { ClaimImageLink } from 'components/SingleEntityHeader/components/ClaimImageLink';
import { useClaimAttachmentsContext } from 'pages/SingleContractPage/context/ClaimAttachmentsProvider';
import { dateFromString } from 'utils/formatDate';
import { HeaderInfoPanel, InfoBold, InfoText } from './components/HeaderInfoPanel';
import './SingleEntityHeader.css';
import { useAppSelector } from 'hooks/reducerHooks';
import classNames from 'classnames';
import { actionStatusStyleModifier } from 'utils/actionStatusModifiers';

export interface SingleEntityHeaderProps {
  claim: ClaimEditValues;
  editClaimHook: EditClaimHook;
}

export const SingleClaimHeader = ({ claim }: SingleEntityHeaderProps) => {
  const counterpartyName = claim?.counterpartyName;
  const matchCode = claim?.matchCode;
  const [isDownloading, setIsDownloading] = useState(false);
  const { actionStatuses } = useAppSelector((s) => s.selectable.data);
  const { actionStatus } = useAppSelector((s) => s.claimActionNameSelection);

  const { attachments } = useClaimAttachmentsContext();

  const attachmentDetails = useMemo(() => {
    // we use as primary document the first claim image found in attachments list
    const claimImage = attachments.find(
      (item) => item.documentType === ClaimAttachmentDocumentType.ClaimImage,
    );
    return {
      documentId: claimImage?.id ?? 0,
      displayName: claimImage?.displayName ?? '',
    };
  }, [attachments]);
  const { documentId, displayName } = attachmentDetails;

  const handleDownloadImage = async () => {
    if (!documentId || isDownloading) return;
    setIsDownloading(true);
    await downloadClaimAttachment(documentId, displayName);
    setIsDownloading(false);
  };

  const formattedActionStatus = useMemo(() => {
    return actionStatuses?.find(
      (status) => status.actionStatus === claim.actionStatus,
    )?.actionStatusDisplayName;
  }, [actionStatuses, claim.actionStatus]);

  return (
    <div className="single-entity-header">
      <div className="single-entity-header__left-pane">
        <div className="single-entity-header__claimsinfo">
          <HeaderInfoPanel>
            <InfoText>{claim.sourceType}</InfoText>
            {claim.officialClaimDate && (
              <InfoText>
                Date Filed: {dateFromString(claim.officialClaimDate)}
              </InfoText>
            )}
          </HeaderInfoPanel>
          <HeaderInfoPanel>
            <InfoBold>Claim ref: #{claim.referenceNumber}</InfoBold>
            {claim.sourceType === SourceType.ScheduledClaim && (
              <InfoBold>
                Claim schedule:{' '}
                {claim.claimScheduleNumber ? `#${claim.claimScheduleNumber}` : '--'}
              </InfoBold>
            )}
          </HeaderInfoPanel>
        </div>

        <div className="single-entity-header__title">{counterpartyName}</div>
        {(!!documentId || claim.claimImage) && (
          <ClaimImageLink
            name={displayName}
            onClick={handleDownloadImage}
            loading={isDownloading}
          />
        )}
      </div>

      <div className="single-entity-header__right-pane">
        <div className="single-entity-header__right-pane__row">
          <HeaderItem id="matchcode" label="Matchcode:" value={matchCode} />

          <HeaderItem
            className={classNames(
              'single-entity-header__action-status-container header-item-vcenter action-status-pill',
              actionStatusStyleModifier(actionStatus ?? claim.actionStatus),
            )}
            id="actionStatus"
            label="Workflow status:"
            value={actionStatus ?? formattedActionStatus ?? ''}
          />
        </div>

        <div className="single-entity-header__right-pane__row">
          <HeaderItem
            className={'single-entity-header__current-debtor-container'}
            id="currentTotal"
            label="Current amount:"
            value={formatCurrency(claim.currentTotal)}
          />
          <HeaderItem
            className={'single-entity-header__current-debtor-container'}
            id="currentDebtor"
            label="Current debtor:"
            value={claim.currentDebtor}
          />
        </div>
      </div>
    </div>
  );
};
