import { useMemo, useState } from 'react';
import {
  ApButtonSecondary,
  ApCheckbox,
  ApInput,
  ApModal,
} from '@alixpartners/ui-components';
import cs from 'classnames';
import { ContractRow } from 'api/endpoints';
import { ButtonMainLoader } from 'components/UIComponents/ButtonMainLoader/ButtonMainLoader';
import { useContractSearchByRef } from 'hooks/useContractSearchByRef';
import '../../ExhibitExporterPage/components/AddClaimPopup/AddClaimPopup.css';
import { formatCurrency } from 'utils/formatNumber';

interface Props {
  onAdd: (contract: ContractRow[]) => void;
  onClose: () => void;
  exhibitContracts: ContractRow[];
}

export const AddContractPopup = ({ onAdd, onClose, exhibitContracts }: Props) => {
  const [search, setSearch] = useState('');
  const [selectedContracts, setSelectedContracts] = useState<ContractRow[]>([]);
  const [selectAll, setSelectAll] = useState(false);

  const contracts = useContractSearchByRef(search);
  const { data, loading } = contracts;

  // let's not show in the search results the contracts already added in the exhibit
  const contractsFound = useMemo(
    () =>
      data?.filter((claim) =>
        exhibitContracts.every((ec) => ec.referenceNumber !== claim.referenceNumber),
      ),
    [data, exhibitContracts],
  );

  const isSearchActive = !!search.length && !!contractsFound?.length;

  const handleAddClick = () => {
    if (selectedContracts?.length) onAdd(selectedContracts);
    setSearch('');
    handleClearSelection();
  };

  const handleSelectContract = (contract: ContractRow) => {
    const alreadySelected = selectedContracts.find(
      (c) => c.referenceNumber === contract.referenceNumber,
    );
    if (alreadySelected) {
      setSelectedContracts((prev) => [
        ...prev.filter(
          (prevContracts) =>
            prevContracts.referenceNumber !== alreadySelected.referenceNumber,
        ),
      ]);
    } else {
      setSelectedContracts((prev) => [...prev, contract]);
    }
  };

  const handleSelectAll = () => {
    setSelectAll((prev) => {
      if (!prev) {
        setSelectedContracts([...contractsFound]);
      } else setSelectedContracts([]);
      return !prev;
    });
  };

  const handleClearSelection = () => {
    setSelectedContracts([]);
    setSelectAll(false);
  };

  return (
    <ApModal
      isDismissible={true}
      isFluid={false}
      isOpen
      zIndex={2}
      className="add-exhibit-item-modal"
      hasClosed={onClose}
      header="Add another contract"
    >
      <div className="add-exhibit-item-popup__content">
        <ApInput
          className="search-input"
          placeholder="Input reference number of contract"
          icon="search"
          label="Search for contract"
          value={search}
          onChange={setSearch}
        />
        <div className={cs('exhibit-search-result', { show: isSearchActive })}>
          <div className="result-title">
            <p>Select the contracts to be added to the exhibit:</p>
            <ApCheckbox
              id="select-all-contracts"
              onClick={handleSelectAll}
              checked={selectAll}
              className="select-all-checkbox"
            >
              Select all {contractsFound.length} contracts
            </ApCheckbox>
          </div>

          {contractsFound?.map((contract) => (
            <div className="result-row" key={contract.referenceNumber}>
              <div>
                <ApCheckbox
                  id={`contract_${contract.referenceNumber}`}
                  onClick={() => handleSelectContract(contract)}
                  checked={
                    !!selectedContracts.find(
                      (c) => c.referenceNumber === contract.referenceNumber,
                    )
                  }
                  className="select-exhibit-item-checkbox"
                />
                #{contract.referenceNumber}
              </div>
              <div>{contract.counterpartyName}</div>
              <div>{formatCurrency(contract.cureCostReductionAmount)}</div>
            </div>
          ))}
        </div>
      </div>
      <div className="add-exhibit-item-popup__footer">
        <ApButtonSecondary
          onClick={handleClearSelection}
          className="exhibit-claim-button"
          disabled={!selectedContracts?.length}
        >
          Clear Selection
        </ApButtonSecondary>
        <ButtonMainLoader
          disabled={!selectedContracts?.length || loading}
          onClick={handleAddClick}
          loading={contracts.loading}
          className="add-exhibit-item-popup__footer_button"
        >
          Add selected {selectedContracts.length > 1 ? ' contracts' : 'contract'}
        </ButtonMainLoader>
      </div>
    </ApModal>
  );
};
