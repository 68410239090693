import React from 'react';
import { NavBarContainer } from 'components/NavBar';

import './SinglePageBase.css';
import { Footer } from 'components/Footer/Footer';
import classNames from 'classnames';

interface IProps {
  hideHeaderMenu?: boolean;
  footer?: boolean;
  className?: string;
  children: React.ReactNode;
}

export const SinglePageContent = ({ children }: { children?: React.ReactNode }) => (
  <div className="singlepage-base__content">{children}</div>
);

export const SinglePageBase = ({
  footer = true,
  hideHeaderMenu,
  children,
  className,
}: IProps) => {
  return (
    <div className="singlepage-base">
      <div className="singlepage-base__nav">
        <NavBarContainer {...{ hideHeaderMenu }} />
      </div>
      <SinglePageContent>
        <div className="singlepage-base__bg-overlay" />
        <div className={classNames('singlepage-base__background', className)}>
          {children}
        </div>
      </SinglePageContent>
      {footer && (
        <div className="singlepage-base__footer">
          <Footer />
        </div>
      )}
    </div>
  );
};
