import { claimKey } from "./Counterparty.types";

export const filedClaimsHeaders = {
  referenceNumber: 'Claim Ref Number',
  counterpartyName: 'Counterparty name',
  subMatchCode: 'Sub-match code',
  majorCategory: 'Major Category',
  currentTotal_formatted: 'Current total',
  currentDebtor: 'Current debtor',
  actionStatus: 'Workflow status',
  officialClaimDate_formatted: 'Date filed',
  claimImage: 'Claim image URL',
  actionName: 'Resolution name',
  actionSubStatusName: 'Resolution subtype',
  reviewLevel: 'Review level',
  originalCurrentProposed: 'Original current proposed',
  originalTotal_formatted: 'Current proposed',
  clientTotal_formatted: 'Proposed total',
  proposedTotal_formatted: 'AlixPartners total',
  counselTotal_formatted: 'Counsel total',
  highTotal_formatted: 'High total',
  lowTotal_formatted: 'Low total'
};

export const scheduledClaimsKeys: claimKey[] = [
  'referenceNumber',
  'counterpartyName',
  'subMatchCode',
  'majorCategory',
  'actionName',
  'actionStatus',
  'actionSubStatusName',
  'officialClaimDate_formatted',
  'currentDebtor',
  'originalTotal_formatted',
  'currentTotal_formatted',
  'proposedTotal_formatted',
];

export const filedClaimsKeys: claimKey[] = [
  ...scheduledClaimsKeys,
  'claimImage',
  'reviewLevel',
  'originalCurrentProposed',
  'clientTotal_formatted',
  'counselTotal_formatted',
  'highTotal_formatted',
  'lowTotal_formatted'
];

export const boldKeys: claimKey[] = [
  'referenceNumber',
  'counterpartyName',
  'clientTotal_formatted',
  'proposedTotal_formatted',
  'counselTotal_formatted',
  'highTotal_formatted',
  'lowTotal_formatted'
];

export const hiddenColumnValues: claimKey[] = [
  'claimImage',
  'reviewLevel',
  'originalCurrentProposed',
];