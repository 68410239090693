import { ContractEditValues } from 'api/endpoints';
import { SectionType } from 'pages/ClaimsContractsToolPage/types';
import { LatestComment } from 'pages/SingleContractPage/components/LatestComment';
import { useContractAttachmentsContext } from 'pages/SingleContractPage/context/ContractAttachmentsProvider';
import { CONTRACT_STATUS_MAP } from 'pages/SingleContractPage/sections/SingleContractOverview/components/CategorySelect/config';
import { EditContractHook } from 'pages/SingleContractPage/sections/SingleContractOverview/hook/useEditContract';
import { useMemo, useState } from 'react';
import { formatCurrency } from 'utils/formatNumber';
import { downloadContractAttachment } from 'utils/uploader';
import {
  HeaderInfoPanel,
  InfoLoadingLink,
  InfoText,
  InfoBold,
} from './components/HeaderInfoPanel';
import { HeaderItem } from './components/HeaderItem';
import { PrimaryDocLink } from './components/PrimaryDocLink';

import './SingleEntityHeader.css';
import { formatDateSimple } from 'utils/formatDate';

export interface SingleEntityHeaderProps {
  contract: ContractEditValues;
  editContractHook?: EditContractHook;
}

export const SingleContractHeader = ({ contract }: SingleEntityHeaderProps) => {
  const { fistImage, primaryDoc } = useContractAttachmentsContext();

  const counterpartyName = contract?.counterpartyName;
  const matchCode = contract?.matchCode;
  const [isDownloading, setIsDownloading] = useState(false);
  const [isPrimaryLoading, setIsPrimaryLoading] = useState(false);

  const contractStatus = useMemo(
    () =>
      contract?.contractStatus
        ? (CONTRACT_STATUS_MAP as any)[contract?.contractStatus] ?? '-'
        : '-',
    [contract?.contractStatus],
  );

  const handleDownloadImage = async () => {
    if (!fistImage || isDownloading) return;
    setIsDownloading(true);
    await downloadContractAttachment(fistImage.id, fistImage.displayName);
    setIsDownloading(false);
  };

  const handleDownloadPrimaryDocument = async () => {
    if (!primaryDoc) return;
    setIsPrimaryLoading(true);
    await downloadContractAttachment(primaryDoc.id, primaryDoc.displayName);
    setIsPrimaryLoading(false);
  };

  return (
    <>
      <div className="single-entity-header">
        <div className="single-entity-header__left-pane">
          <HeaderInfoPanel>
            {!fistImage?.id ? (
              <InfoBold>Contract Ref No: {contract.referenceNumber}</InfoBold>
            ) : (
              <InfoLoadingLink
                onClick={handleDownloadImage}
                loading={isDownloading}
                title={'Click to download the Contract image'}
              >
                #{contract.referenceNumber}
              </InfoLoadingLink>
            )}
            <InfoText>{contract.sourceType || 'Contract'}</InfoText>
            {!!contract.contractDate && (
              <InfoText>
                Contract Date: {formatDateSimple(contract.contractDate)}
              </InfoText>
            )}
          </HeaderInfoPanel>

          <div className="single-entity-header__title">{counterpartyName}</div>

          {primaryDoc && (
            <PrimaryDocLink
              name={primaryDoc.displayName}
              loading={isPrimaryLoading}
              onClick={handleDownloadPrimaryDocument}
            />
          )}
        </div>

        <div className="single-entity-header__right-pane">
          <div className="single-entity-header__right-pane__row">
            <HeaderItem id="matchcode" label="Matchcode:" value={matchCode ?? '-'} />

            <HeaderItem
              className={'single-entity-header__current-debtor-container'}
              id="approvalStatus"
              label="Workflow Status:"
              value={contractStatus}
            />
          </div>

          <div className="single-entity-header__right-pane__row">
            <HeaderItem
              id="currentTotal"
              label="LTM Spend:"
              value={formatCurrency(contract.lastTwelveMonthsSpend) || '-'}
            />

            <HeaderItem
              className={'single-entity-header__current-debtor-container'}
              id="lId"
              label="LID:"
              value={contract.lID ?? '-'}
            />
            <HeaderItem
              className={'single-entity-header__current-debtor-container'}
              id="currentDebtor"
              label="Primary debtor:"
              value={contract.primaryDebtor ?? '-'}
            />
          </div>
        </div>
      </div>
      <LatestComment id={contract.id} section={SectionType.Contracts} />
    </>
  );
};
