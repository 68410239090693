import { ApButtonMain, ApTextInput } from '@alixpartners/ui-components';
import { ApButtonCasual } from 'components/UIComponents/Buttons/ApButtonCasual';
import { SvgIcon } from 'components/UIComponents/SvgIcon';
import { forwardRef, useCallback, useState } from 'react';
import { fileToArrayBuffer } from 'utils/file';

import { WizardFieldLabel } from 'pages/SetupWizardPage/component';
import AvatarEditor, { Position } from 'react-avatar-editor';
import { useDropzone } from 'react-dropzone';
import { InputRange } from './InputRange';
import './LogoSelector.css';

const IMAGE_WIDTH = 320;
const IMAGE_HEIGHT = 80;
const IMAGE_BORDER = 25;

export interface LogoSelectorProps {
  logo?: string;
  onLogoUploaded: (
    content: ArrayBuffer,
    filename: string,
    fileType: string,
  ) => unknown;
  onLogoRemoved: () => void;
}

export const LogoSelector = forwardRef<AvatarEditor, LogoSelectorProps>(
  ({ logo, onLogoUploaded, onLogoRemoved }, ref) => {
    const [file, setFile] = useState<any>();

    const [imageRotate, setImageRotate] = useState(0);
    const [imageScale, setImageScale] = useState(1);
    const [imagePosition, setImagePosition] = useState<Position>({ x: 0.5, y: 0.5 });

    const onDrop = useCallback(
      async (files: File[]) => {
        if (!files?.length) return;

        const file = files[0];
        setFile(file);

        const content = await fileToArrayBuffer(file);
        onLogoUploaded(content, file.name, file.type);
      },
      [onLogoUploaded],
    );

    const dropZone = useDropzone({
      maxFiles: 1,
      noClick: true,
      noKeyboard: true,
      accept: ['.png', '.gif', '.jpg', '.jpeg', '.jfif', '.jif', '.webp'],
      onDrop,
    });

    const onRemoveLogoHandler = () => {
      setFile({});
      onLogoRemoved();
    };

    return (
      <>
        <div className="client-name-logo__line">
          {logo || file?.path ? (
            <>
              <ApButtonCasual
                style={{ textTransform: 'none' }}
                iconName="close"
                onClick={onRemoveLogoHandler}
              >
                Remove logo
              </ApButtonCasual>

              {file?.path && (
                <div className="client-name-logo__filename">
                  <ApTextInput id="filename" value={file?.name} readOnly />
                </div>
              )}
            </>
          ) : (
            <ApButtonMain onClick={dropZone.open}>Upload Logo</ApButtonMain>
          )}
        </div>

        <div className="client-name-logo__image" {...dropZone.getRootProps()}>
          <input {...dropZone.getInputProps()} />
          <WizardFieldLabel
            text="Uploaded logo"
            info="Please ensure that the logo you are uploading is visible on the color background you are using. 
We recommend you to upload image up to 2 MB, in landscape format."
          />
          <div className="client-name-logo__info-text">
            You can drag the image or use the zoom to fit it into the visible area.
          </div>

          {logo || file?.path ? (
            <AvatarEditor
              ref={ref}
              image={logo ?? ''}
              width={IMAGE_WIDTH}
              height={IMAGE_HEIGHT}
              border={IMAGE_BORDER}
              color={[200, 200, 200, 0.5]} // RGBA
              scale={imageScale}
              rotate={imageRotate}
              position={imagePosition}
              onPositionChange={setImagePosition}
            />
          ) : (
            <ImageDropZone
              style={{
                width: `${IMAGE_WIDTH + 2 * IMAGE_BORDER}px`,
                height: `${IMAGE_HEIGHT + 2 * IMAGE_BORDER}px`,
              }}
              onClick={dropZone.open}
            />
          )}
        </div>

        <div className="client-name-logo__image-controls">
          <InputRange
            name="Rotate"
            min={-180}
            max={180}
            step={1}
            value={imageRotate}
            onValueChange={setImageRotate}
          />
          <InputRange
            name="Zoom"
            min={0.5}
            max={3}
            step={0.1}
            value={imageScale}
            onValueChange={setImageScale}
          />
        </div>
      </>
    );
  },
);

const ImageDropZone = (props: React.ComponentProps<'div'>) => {
  return (
    <div className="client-name-logo__drop-zone" {...props}>
      <SvgIcon iconName="svgImageFile" />
    </div>
  );
};
