import { fromCamelCase } from 'utils/format';
import { getIsoDate } from 'utils/formatDate';
import { formatCurrency } from 'utils/formatNumber';

const inputValueFormatter = (value: string, field?: string) => {
  if (!value) return '';
  if (field === 'actionReview') {
    const result = fromCamelCase(value);
    return result.replace('Alix Partners', 'AlixPartners');
  }
  return value;
};

export const formatValue = ({
  value,
  dateField,
  field,
}: {
  value: string | number;
  dateField?: boolean;
  field?: string;
}) => {
  if (dateField && typeof value === 'string') return getIsoDate(new Date(value));
  if (typeof value === 'number') return formatCurrency(value);
  return inputValueFormatter(value, field);
};
