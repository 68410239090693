import {
  ApButtonSecondary,
  ApCheckbox,
  ApInput,
  ApModal,
} from '@alixpartners/ui-components';
import cs from 'classnames';
import { useMemo, useState } from 'react';
import { useAppDispatch } from '../../../../hooks/reducerHooks';
import { setClaims } from '../../../../reducer/exhibits/exhibitReducer';

import { ButtonMainLoader } from 'components/UIComponents/ButtonMainLoader';
import { useClaimsSearchByRef } from 'hooks/useClaimsSearchByRef';
import './AddClaimPopup.css';
import { ClaimRow } from 'api/endpoints';
import { formatCurrency } from 'utils/formatNumber';

export const AddClaimPopup = ({
  handleClose,
  exhibitClaims,
}: {
  handleClose: () => void;
  exhibitClaims: ClaimRow[];
}) => {
  const dispatch = useAppDispatch();
  const [search, setSearch] = useState('');
  const [selectedClaims, setSelectedClaims] = useState<ClaimRow[]>([]);
  const [selectAll, setSelectAll] = useState(false);

  const claims = useClaimsSearchByRef(search);

  // let's not show in the search results the claims already added in the exhibit
  const claimsFound = useMemo(
    () =>
      claims?.data.filter((claim) =>
        exhibitClaims.every((ec) => ec.referenceNumber !== claim.referenceNumber),
      ),
    [claims?.data, exhibitClaims],
  );

  const hasValue = !!selectedClaims?.length;

  const handleAddClick = () => {
    if (!hasValue) return;

    dispatch(setClaims([...selectedClaims]));
    setSearch('');
  };

  const handleSelectClaim = (claim: ClaimRow) => {
    const alreadySelected = selectedClaims.find(
      (c) => c.referenceNumber === claim.referenceNumber,
    );
    if (alreadySelected) {
      setSelectedClaims((prev) => [
        ...prev.filter(
          (prevClaims) =>
            prevClaims.referenceNumber !== alreadySelected.referenceNumber,
        ),
      ]);
    } else {
      setSelectedClaims((prev) => [...prev, claim]);
    }
  };

  const handleSelectAll = () => {
    setSelectAll((prev) => {
      if (!prev) {
        setSelectedClaims([...claimsFound]);
      } else setSelectedClaims([]);
      return !prev;
    });
  };

  const handleClearSelection = () => {
    setSelectedClaims([]);
    setSelectAll(false);
  };

  return (
    <ApModal
      isDismissible={true}
      isFluid={false}
      isOpen={true}
      zIndex={2}
      className="add-exhibit-item-modal"
      hasClosed={handleClose}
      header="Add another claim"
    >
      <div className="add-exhibit-item-popup__content">
        <ApInput
          className="search-input"
          placeholder="Input reference number of claim"
          icon="search"
          label="Search for claim"
          value={search}
          onChange={(value) => setSearch(value)}
        />
        <div
          className={cs('exhibit-search-result', { show: !!claimsFound?.length })}
        >
          <div className="result-title">
            <p>Select the claims to be added to the exhibit:</p>
            <ApCheckbox
              id="select-all-claims"
              onClick={handleSelectAll}
              checked={selectAll}
              className="select-all-checkbox"
            >
              Select all {claimsFound.length} claims
            </ApCheckbox>
          </div>
          {claimsFound?.map((claim) => (
            <div className="result-row" key={claim.referenceNumber}>
              <div>
                <ApCheckbox
                  id={`claim_${claim.referenceNumber}`}
                  onClick={() => handleSelectClaim(claim)}
                  checked={
                    !!selectedClaims.find(
                      (c) => c.referenceNumber === claim.referenceNumber,
                    )
                  }
                  className="select-exhibit-item-checkbox"
                />
                #{claim.referenceNumber}
              </div>
              <div>{claim.counterpartyName}</div>
              <div>{formatCurrency(claim.currentTotal)}</div>
            </div>
          ))}
        </div>
      </div>
      <div className="add-exhibit-item-popup__footer">
        <ApButtonSecondary
          onClick={handleClearSelection}
          className="exhibit-claim-button"
          disabled={!selectedClaims?.length}
        >
          Clear Selection
        </ApButtonSecondary>
        <ButtonMainLoader
          disabled={!hasValue || claims.loading}
          className="add-exhibit-item-popup__footer_button"
          onClick={handleAddClick}
          loading={claims.loading}
        >
          Add selected {selectedClaims.length > 1 ? ' claims' : 'claim'}
        </ButtonMainLoader>
      </div>
    </ApModal>
  );
};
