import _ from 'lodash';
import isNil from 'lodash/isNil';
import memoize from 'lodash/memoize';
import { CURRENCY } from '../const';

export enum ValueType {
  Currency = 1,
  Percentage = 2,
  Ratio = 3,
  Number = 4,
  Days = 5,
}

const BILLION = 1000000000;
const MILLION = 1000000;
const THOUSAND = 1000;

export const getFactor = (denomination: number) => {
  if (denomination >= BILLION) return 'B';
  if (denomination >= MILLION) return 'M';
  if (denomination >= THOUSAND) return 'K';

  // Safer to make no assumption here
  return '';
};

export interface NumberFormatOptions {
  /**
   * BCP 47 language tag
   */
  locale?: string;
  /**
   * Factorize number to thousands / millions / billions.
   * E.g. 10,000 with `denomination: 1000` will be shown as 10K.
   */
  denomination?: number;
  /**
   * Decimal will set both minimumFractionDigits and maximumFractionDigits.
   */
  decimal?: number;
  /**
   * ISO-4217 currency code
   */
  currency?: string;
  /**
   * Decimal by default. To show the currency sign, use style: 'currency'.
   */
  style?: 'decimal' | 'currency';
  /**
   * Append a factor according to denomination.
   */
  appendFactor?: boolean;
  /**
   * Arbitrary suffix.
   */
  suffix?: string;
  /**
   * Wrap negative numbers in parentheses instead of displaying
   * the minus sign for higher visibility.
   */
  negativeInParentheses?: boolean;
  /**
   * Applies default formatting for table cells which display financial data.
   * Sets negativeInParentheses, appendFactor and disables currency signs.
   */
  financialModelFormat?: boolean;
  /**
   * Applies default formatting for variances.
   * Caps maximum fraction digits at 2.
   */
  varianceFormat?: boolean;
  /**
   * Intl.NumberFormat option.
   */
  minimumFractionDigits?: number;
  /**
   * Intl.NumberFormat option.
   */
  maximumFractionDigits?: number;
}

/**
 * Caching Intl.NumberFormat can result in 75x speed improvement
 * https://www.google.com/search?q=Intl.NumberFormat+slow
 */
const memoizedNumberFormat = memoize(
  (locale, minimumFractionDigits, maximumFractionDigits, style, currency) =>
    new Intl.NumberFormat(locale, {
      style,
      minimumFractionDigits,
      maximumFractionDigits,
      currency,
    }),
  (...args) => args.join('-'),
);

/**
 * Like Intl.NumberFormat but supports additional styles
 * used for financial modelling data.
 *
 * For most purposes see formatNumberByType() below
 * or the <FormattedNumber /> component.
 */
export const formatNumberWithOptions = (
  num: number,
  options: NumberFormatOptions = {},
): string => {
  const { decimal, financialModelFormat = false } = options;

  const {
    denomination = 1,
    appendFactor = !financialModelFormat,
    suffix = '',
    negativeInParentheses = financialModelFormat,
    minimumFractionDigits: minimumFractionDigitsProp = decimal,
    maximumFractionDigits = decimal,
    currency,
    locale,
    style = 'decimal',
  } = options;

  const result = num / denomination;

  // Format 0 as '0', never as '0.000' (PBI 20975)
  // Format 0.0002 as '0.000' if it is rounded to '0.000' (PBI 20975)
  // Format 0.2300 as '0.230' including the trailing zero (PBI 22889)
  const isRoundedToZero = isNil(maximumFractionDigits)
    ? result === 0
    : Math.round(result * 10 ** maximumFractionDigits) === 0;
  const minimumFractionDigits = isRoundedToZero ? 0 : minimumFractionDigitsProp;

  if (negativeInParentheses && num < 0) {
    const nested = formatNumberWithOptions(Math.abs(num), options);
    return isRoundedToZero ? nested : `(${nested})`;
  }

  const intl = memoizedNumberFormat(
    locale,
    minimumFractionDigits,
    maximumFractionDigits,
    style,
    currency,
  );
  // Format -0 as "0", never as "-0"
  const string = intl.format(result === 0 ? 0 : result);
  if (appendFactor) {
    return `${string}${getFactor(denomination)}${suffix}`;
  }

  return `${string}${suffix}`;
};

/**
 * Applies financial model formatting to a number
 * @deprecated
 */
export const formatNumber = (
  num: number,
  denomination: number,
  decimal: number = 1,
) =>
  formatNumberWithOptions(num, {
    denomination,
    financialModelFormat: true,
    decimal,
  });

/**
 * To display 1000% as 1K% and 1,000,000% as 1M% we need to determine
 * what's the ideal denomination for a value.
 *
 * `getIdealDenomination(10000) //=> { denomination: 10^3, suffix: 'K' }`
 * `getIdealDenomination(1000) //=> { denomination: 10^3, suffix: 'K' }`
 * `getIdealDenomination(100) //=> { denomination: 1, suffix: '' }`
 */
export const getIdealDenomination = (value: number): number => {
  const candidates = [BILLION, MILLION, THOUSAND, 1];
  const denomination: any = candidates.find(
    (candidate) => Math.abs(value) / candidate > 1,
  );
  return denomination || 1;
};

/**
 * Return a valid value type or ValueType.Currency by default.
 * For example summary line items are missing a value type.
 */
export const getValueTypeById = (
  valueTypeId: number | undefined | null,
): ValueType =>
  // @ts-ignore
  Object.values(ValueType).includes(valueTypeId)
    ? (valueTypeId as any)
    : ValueType.Currency;

/**
 * Format a number based on its value type (percentage, ratio, currency).
 *
 * Denomination is applicable to CURRENCY type only.
 *
 * See also <FormattedNumber /> component that uses react-intl to pass
 * the current locale.
 */
export const formatNumberByType = (
  value: number,
  valueType: ValueType,
  options: NumberFormatOptions = {},
) => {
  // TODO Remove. This is defensive against null value types.
  const type = getValueTypeById(valueType);
  if (type === ValueType.Currency) {
    return formatNumberWithOptions(value, options);
  }

  const number = type === ValueType.Percentage ? value * 100 : value;
  const denomination = getIdealDenomination(number);
  const disableParentheses = { negativeInParentheses: false };
  const defaultSuffix = (
    {
      [ValueType.Percentage]: '%',
      [ValueType.Days]: ` day${value !== 1 ? 's' : ''}`,
    } as any
  )[type];
  const suffix = options.suffix ?? defaultSuffix ?? '';

  const decimal = (() => {
    if (valueType === ValueType.Days) {
      return 0;
    } else if (options.varianceFormat && !isNil(options.decimal)) {
      return Math.min(options.decimal, 2);
    } else {
      return options.decimal;
    }
  })();

  return formatNumberWithOptions(number, {
    appendFactor: true,
    ...options,
    // options.denomination is applicable to currency only
    denomination,
    decimal,
    suffix,
    currency: undefined,
    style: 'decimal',
    // Types other than currency and number must never be shown in parentheses
    // Only override this option for percentage and ratio.
    ...(type !== ValueType.Number ? disableParentheses : null),
  });
};

/**
 *
 * Returns suffix for valueType, for currency it'll return the correct currency sign
 */
export const getSuffix = (
  valueType: ValueType,
  currency: string | undefined,
): string =>
  ((
    {
      [ValueType.Percentage]: '%',
      [ValueType.Days]: 'days',
      [ValueType.Currency]: (currency && getCurrencySymbol(currency)) || '',
    } as any
  )[valueType] || '');

/**
 * Return a currency sumbol, e.g. '£' or 'US$'
 * Based on Intl.NumberFormat().
 * See https://en.wikipedia.org/wiki/ISO_4217
 */
export const getCurrencySymbol = memoize((currency: string): string => {
  const intl = new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency,
    currencyDisplay: 'symbol',
  });
  const parts = intl.formatToParts();
  const part = parts.find((x) => x.type === 'currency');
  return part ? part.value : currency;
});

export const formatCurrency = (value?: number): string =>
  typeof value !== 'undefined'
    ? `${CURRENCY}${formatNumberByType(value, ValueType.Currency)}`
    : '';

export const asNumber = (value?: number | string) => {
  if (_.isNil(value)) return undefined;

  const result = +value;
  return isFinite(result) ? result : undefined;
};

const formatterUsaCurrency = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
});

export const formatExcelUSACurrency = (value?: number): string => {
  if (isNil(value) || !isFinite(value)) return '';

  //fix negative zero
  const fixedValue = Object.is(value, -0) ? 0 : value;

  return formatterUsaCurrency.format(fixedValue);
};
