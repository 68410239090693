import AzureStorageClient from 'utils/azureStorageClient';
import { v4 as uuidv4 } from 'uuid';

import { downloadFile } from 'api/apiRequest';
import {
  BlobStorageCredentials,
  SofaOrScheduleFileType,
  storage,
} from 'api/endpoints';
import _, { isEmpty } from 'lodash';
import { FileType } from 'types/dataUploaderTypes';
import { filenameSanitizer } from './file';

/* **** Deprecated **** */
/* Use azureFilesUploader instead */

const azureStorageGenerator = (
  getCredentials: () => Promise<BlobStorageCredentials | any>,
) => new AzureStorageClient({ getCredentials });

const UPLOADER = {
  logo: azureStorageGenerator(storage.getLogoContainerSasToken),
  excel: azureStorageGenerator(storage.getExcelContainerSasToken),
  claimAttachment: azureStorageGenerator(storage.getClaimDocumentsContainerSasToken),
  conctractAttachment: azureStorageGenerator(
    storage.getContractDocumentsContainerSasToken,
  ),
  contractImages: azureStorageGenerator(storage.getContractImagesContainerSasToken),
  contractZipImages: azureStorageGenerator(
    storage.getContractImagesZipContainerSasToken,
  ),
  claimZipImages: azureStorageGenerator(storage.getClaimImagesZipContainerSasToken),
};

export function generateClaimAttachmentDownloadUrl(documentId: number) {
  return `v1/ClaimDocuments/download/${documentId}`;
}

export function generateContractAttachmentDownloadUrl(documentId: number) {
  return `v1/ContractDocuments/download/${documentId}`;
}

export function generateClaimChangeLogExportDownloadUrl(claimId: number) {
  return `v1/ClaimChangeLog/excelExport/?claimId=${claimId}`;
}

export function generateContractChangeLogExportDownloadUrl(contractId: number) {
  return `v1/ContractChangeLog/excelExport/?contractId=${contractId}`;
}

export function downloadClaimAttachment(
  documentId: number,
  fileNameWhenDownloaded: string,
) {
  const url = generateClaimAttachmentDownloadUrl(documentId);
  return downloadFile(url, fileNameWhenDownloaded);
}

export function downloadContractAttachment(
  documentId: number,
  fileNameWhenDownloaded: string,
) {
  const url = generateContractAttachmentDownloadUrl(documentId);
  return downloadFile(url, fileNameWhenDownloaded);
}

export async function uploadLogo(props: {
  logoContent: ArrayBuffer;
  name?: string;
  type?: string;
}) {
  const { logoContent, name = '', type = '' } = props;

  const fileId = `${uuidv4()}_${name ?? ''}`;
  const uploader = UPLOADER.logo;
  try {
    await uploader.uploadBuffer(logoContent, fileId, {
      blobHTTPHeaders: {
        blobContentType: type,
      },
    });

    return fileId;
  } catch (error: any) {
    // @todo: implement global app wide alert system with our own UI
    console.error(error);
    error('Logo upload failed');
  }
}

export async function uploadExcel(
  file: File,
  type: FileType,
  onProgress?: (loaded: number, size: number) => unknown,
): Promise<{ name: string } | undefined> {
  const { fileUniqueName, fileFullPath } = createAzureFileName({
    file: file.name,
    folder: FileType[type],
  });

  try {
    await UPLOADER.excel.upload(file, fileFullPath, {
      onProgress: ({ loadedBytes }) => onProgress?.(loadedBytes, file.size),
    });
    return { name: fileUniqueName };
  } catch (error: any) {
    // @todo: implement global app wide alert system with our own UI
    console.error(error);
    throw error;
  }
}

export async function uploadClaimImage(
  file: File,
  abortSignal: AbortSignal,
  onProgress?: (loaded: number, size: number) => unknown,
): Promise<{ name: string } | undefined> {
  const { fileUniqueName, fileFullPath } = createAzureFileName({
    file: file.name,
    folder: FileType.ClaimImage,
  });

  try {
    await UPLOADER.claimAttachment.upload(file, fileFullPath, {
      onProgress: ({ loadedBytes }) => onProgress?.(loadedBytes, file.size),
      abortSignal,
    });
    return { name: fileUniqueName };
  } catch (error: any) {
    // @todo: implement global app wide alert system with our own UI
    console.error(error);
    throw error;
  }
}

export async function uploadClaimZipImage(
  file: File,
  abortSignal: AbortSignal,
  onProgress?: (loaded: number, size: number) => unknown,
): Promise<{ name: string } | undefined> {
  const { fileUniqueName, fileFullPath } = createAzureFileName({
    file: file.name,
    folder: '',
  });

  try {
    await UPLOADER.claimZipImages.upload(file, fileFullPath, {
      onProgress: ({ loadedBytes }) => onProgress?.(loadedBytes, file.size),
      abortSignal,
    });
    return { name: fileUniqueName };
  } catch (error: any) {
    console.error(error);
    throw error;
  }
}

export async function uploadContractImage(
  file: File,
  abortSignal: AbortSignal,
  onProgress?: (loaded: number, size: number) => unknown,
): Promise<{ name: string } | undefined> {
  const { fileUniqueName, fileFullPath } = createAzureFileName({
    file: file.name,
    folder: FileType.ContractImage,
  });

  try {
    await UPLOADER.contractImages.upload(file, fileFullPath, {
      onProgress: ({ loadedBytes }) => onProgress?.(loadedBytes, file.size),
      abortSignal,
    });
    return { name: fileUniqueName };
  } catch (error: any) {
    // @todo: implement global app wide alert system with our own UI
    console.error(error);
    throw error;
  }
}

export async function uploadContractZipImage(
  file: File,
  abortSignal: AbortSignal,
  onProgress?: (loaded: number, size: number) => unknown,
): Promise<{ name: string } | undefined> {
  const { fileUniqueName, fileFullPath } = createAzureFileName({
    file: file.name,
    folder: '',
  });

  try {
    await UPLOADER.contractZipImages.upload(file, fileFullPath, {
      onProgress: ({ loadedBytes }) => onProgress?.(loadedBytes, file.size),
      abortSignal,
    });
    return { name: fileUniqueName };
  } catch (error: any) {
    // @todo: implement global app wide alert system with our own UI
    console.error(error);
    throw error;
  }
}

export async function uploadContractImageZipMapping(
  file: File,
  abortSignal: AbortSignal,
  onProgress?: (loaded: number, size: number) => unknown,
): Promise<{ name: string } | undefined> {
  const { fileUniqueName, fileFullPath } = createAzureFileName({
    file: file.name,
    folder: FileType.ContractImageZipMap,
  });

  try {
    await UPLOADER.excel.upload(file, fileFullPath, {
      onProgress: ({ loadedBytes }) => onProgress?.(loadedBytes, file.size),
      abortSignal,
    });
    return { name: fileUniqueName };
  } catch (error: any) {
    // @todo: implement global app wide alert system with our own UI
    console.error(error);
    throw error;
  }
}

export async function uploadClaimImageZipMapping(
  file: File,
  abortSignal: AbortSignal,
  onProgress?: (loaded: number, size: number) => unknown,
): Promise<{ name: string } | undefined> {
  const { fileUniqueName, fileFullPath } = createAzureFileName({
    file: file.name,
    folder: FileType.ClaimImageMap,
  });

  try {
    await UPLOADER.excel.upload(file, fileFullPath, {
      onProgress: ({ loadedBytes }) => onProgress?.(loadedBytes, file.size),
      abortSignal,
    });
    return { name: fileUniqueName };
  } catch (error: any) {
    // @todo: implement global app wide alert system with our own UI
    console.error(error);
    throw error;
  }
}

export async function uploadClaimAttachment(
  file: File,
  claimId: number,
  onProgress?: (loaded: number, size: number) => unknown,
): Promise<{ name: string } | undefined> {
  const { fileUniqueName, fileFullPath } = createAzureFileName({
    file: file.name,
    folder: `${claimId}`,
  });

  try {
    await UPLOADER.claimAttachment.upload(file, fileFullPath, {
      onProgress: ({ loadedBytes }) => onProgress?.(loadedBytes, file.size),
    });

    return { name: fileUniqueName };
  } catch (error: any) {
    console.error(error);
    throw error;
  }
}

export async function uploadContractAttachment(
  file: File,
  contractId: number,
  onProgress?: (loaded: number, size: number) => unknown,
): Promise<{ name: string } | undefined> {
  const { fileUniqueName, fileFullPath } = createAzureFileName({
    file: file.name,
    folder: `${contractId}`,
  });

  try {
    await UPLOADER.conctractAttachment.upload(file, fileFullPath, {
      onProgress: ({ loadedBytes }) => onProgress?.(loadedBytes, file.size),
    });
    return { name: fileUniqueName };
  } catch (error: any) {
    console.error(error);
    throw error;
  }
}

export async function uploadGlobalNote(
  file: File,
  fileType: SofaOrScheduleFileType,
  onProgress?: (loaded: number, size: number) => unknown,
): Promise<{ fileName: string } | undefined> {
  const { fileUniqueName, fileFullPath } = createAzureFileName({
    file: file.name,
    folder: `${fileType}/GlobalNotes`,
  });

  try {
    await UPLOADER.excel.upload(file, fileFullPath, {
      onProgress: ({ loadedBytes }) => onProgress?.(loadedBytes, file.size),
    });
    return { fileName: fileUniqueName };
  } catch (error: any) {
    console.error(error);
    throw error;
  }
}

export async function uploadSlipSheet(
  file: File,
  onProgress?: (loaded: number, size: number) => unknown,
): Promise<{ fileName: string } | undefined> {
  const { fileUniqueName, fileFullPath } = createAzureFileName({
    file: file.name,
    folder: `SlipSheets`,
  });

  try {
    await UPLOADER.excel.upload(file, fileFullPath, {
      onProgress: ({ loadedBytes }) => onProgress?.(loadedBytes, file.size),
    });
    return { fileName: fileUniqueName };
  } catch (error: any) {
    console.error(error);
    throw error;
  }
}

export const fetchUrlFileToBuffer = async (url: string) => {
  if (isEmpty(url)) return undefined;

  try {
    const response = await fetch(url);
    if (!response.ok) return undefined;

    return await response.arrayBuffer();
  } catch {
    console.error(`Download failed: ${url}`);
    return undefined;
  }
};

//---
function createAzureFileName(props: { file: string; folder: string }) {
  const folderPath = !_.isEmpty(props.folder) ? `${props.folder}/` : '';

  const validFileName = filenameSanitizer(props.file);

  const fileUniqueName = `${uuidv4()}_${validFileName}`;
  const fileFullPath = `${folderPath}${fileUniqueName}`;

  return { fileUniqueName, fileFullPath };
}
