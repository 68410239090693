import { ClaimRow } from 'api/endpoints';
import { ActionName } from 'api/endpoints/action-names';
import Excel from 'exceljs';
import compact from 'lodash/compact';
import map from 'lodash/map';
import sum from 'lodash/sum';
import times from 'lodash/times';
import { IExhibitState } from 'reducer/exhibits/exhibitReducer';
import { formatCurrency, formatExcelUSACurrency } from 'utils/formatNumber';
import { iif } from 'utils/object';
import { IHeaderFooter } from './type';

export const pageNumberValue = 'Page &P';

const excelTemplatePath = `${process.env.PUBLIC_URL}/templates`;

// @todo: move to global utils file
export const loadTemplate = async (
  templateName: string,
  templateType: string = 'exhibit-exporter',
) => {
  const response = await fetch(
    `${excelTemplatePath}/${templateType}/${templateName}`,
  );
  return await response.arrayBuffer();
};

export const insertNumberOrEmptyRows = (
  sheet: Excel.Worksheet,
  position: number,
  count: number,
) => {
  sheet.insertRows(
    position,
    times(count, () => []),
  );
};

export const getTotalCurrency = (claims: any[], fieldName: string): string => {
  const value = sum(map(claims, (r) => r[fieldName]));

  return formatCurrency(value);
};

export const getAddressLine = (c: ClaimRow | undefined) => {
  if (!c) return '';

  const addresses = [c.address1, c.address2, c.address3, c.address4]
    .map((a) => a?.trim())
    .filter((address) => address && address.length)
    .join('\n');

  return `${c.counterpartyName} ${showValueWithNewLine(
    addresses,
  )} ${showValueWithNewLine(
    [c.city, c.state, c.zip]
      .map((a) => a?.trim())
      .filter((c) => c && c.length)
      .join(' '),
  )} ${showValueWithNewLine(c.country)}`;
};

export function createClaimAmount(c: ClaimRow | undefined): string {
  if (!c) return '';

  return (
    `${formatExcelUSACurrency(c.currentSecured)} (S)\n` +
    `${formatExcelUSACurrency(c.currentAdministrative)} (A)\n` +
    `${formatExcelUSACurrency(c.currentPriority)} (P)\n` +
    `${formatExcelUSACurrency(c.currentUnsecured)} (U)\n` +
    `${formatExcelUSACurrency(c.currentTotal)} (T)`
  );
}

export function createReducedClaimAmount(c: ClaimRow): string {
  return (
    `${formatExcelUSACurrency(c.proposedSecured)} (S)\n` +
    `${formatExcelUSACurrency(c.proposedAdministrative)} (A)\n` +
    `${formatExcelUSACurrency(c.proposedPriority)} (P)\n` +
    `${formatExcelUSACurrency(c.proposedUnsecured)} (U)\n` +
    `${formatExcelUSACurrency(c.proposedTotal)} (T)`
  );
}

export const createHeaderFooter = (
  data: IExhibitState,
  action: ActionName | undefined,
): IHeaderFooter => {
  const {
    structureFooterOptions,
    structureHeaderOptions,
    globalFootnotes,
    primaryCaseNumber,
    projectName,
  } = data;
  const showGlobalFooter = !!structureFooterOptions.find(
    (r) => `${r.value}` === '1',
  );

  const showHearingName = !!structureHeaderOptions.find((r) => `${r.value}` === '1');
  const showExhibitName = !!structureHeaderOptions.find((r) => `${r.value}` === '2');
  const showBasisForObjct = !!structureHeaderOptions.find(
    (r) => `${r.value}` === '3',
  );
  const showCaseNumber = !!structureHeaderOptions.find((r) => `${r.value}` === '4');
  const showCaseName = !!structureHeaderOptions.find((r) => `${r.value}` === '5');

  const actionName = iif(showHearingName, action?.name);
  const exhibitName = iif(showExhibitName, data.exhibitNumber ?? 'Exhibit 1');
  const basisForObj = iif(showBasisForObjct, data.objectionBasis);
  const caseNumber = iif(showCaseNumber, primaryCaseNumber);
  const caseName = iif(showCaseName, projectName);

  const fistPageGlobal: string[] = showGlobalFooter
    ? globalFootnotes.map((r) => r.footnote)
    : [];

  const allPageGlobal: string[] = showGlobalFooter
    ? globalFootnotes
        .filter((r) => r.footnoteAppearOptionId !== 1) //all footnotes except the firstpage
        .map((r) => r.footnote)
    : [];

  return {
    header: {
      left: multilineText(actionName, basisForObj),
      middle: multilineText(exhibitName),
      right: multilineText(
        showValueWithLabel('Primary case number', caseNumber),
        showValueWithLabel('Project name', caseName),
      ),
    },
    bottomFirst: {
      left: multilineFooterText(...fistPageGlobal),
      middle: '',
      right: pageNumberValue,
    },
    bottom: {
      left: multilineFooterText(...allPageGlobal),
      middle: '',
      right: pageNumberValue,
    },
  };
};

const multilineFooterText = (...value: (string | null | undefined)[]) => {
  const text = multilineText(...value);
  // character "&" is a special character that is used for formatting
  const withAllowedCharacters = text.replaceAll('&', '&&');
  return withAllowedCharacters;
};

const multilineText = (...value: (string | null | undefined)[]) =>
  compact(value).join(`\n`);

const showValueWithNewLine = (value: string | undefined) =>
  value ? `\n${value}` : '';

const showValueWithLabel = (label: string, value?: string | number) => {
  return value ? `${label}: ${value}` : '';
};

export const getChildDataByRefNumber = (
  childClaims: ClaimRow[],
  referenceNumber: string,
) => {
  const originalDebtors: string[] = [];
  const proposedDebtors: string[] = [];
  const claimAmounts: string[] = [];
  const reducedClaimAmounts: string[] = [];

  childClaims
    .filter((c) => c.parentClaimNumber === referenceNumber)
    .forEach((c) => {
      if (c.originalDebtor) {
        originalDebtors.push(c.originalDebtor);
      }
      if (c.proposedDebtor) {
        proposedDebtors.push(c.proposedDebtor);
      }
      claimAmounts.push(createClaimAmount(c));
      reducedClaimAmounts.push(createReducedClaimAmount(c));
    });
  return {
    originalDebtors: formatListToString(originalDebtors, '\n\n\n\n\n\n'), // 6 new lines bc of 5 claimAmounts lines and blank line
    proposedDebtors: formatListToString(proposedDebtors, '\n\n\n\n\n\n'),
    claimAmounts: formatListToString(claimAmounts, '\n\n'),
    reducedClaimAmounts: formatListToString(reducedClaimAmounts, '\n\n'),
  };
};

const formatListToString = (toJoin: string[], joinWith: string) => {
  if (!toJoin.length) return;
  return toJoin.join(joinWith);
};
