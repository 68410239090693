import {
  ApButtonLink,
  ApIcon,
  ApLoaderDefault,
  ApMenuItem,
} from '@alixpartners/ui-components';
import { ApButtonCasual } from 'components/UIComponents/Buttons/ApButtonCasual';
import { DocumentsPageUploadedFiles } from './DocumentsPageUploadedFiles';
import { fileInfo } from 'api/endpoints';
import { SectionType, SubsectionType } from 'pages/ClaimsContractsToolPage/types';
import { DocumentsUploadSection } from './DocumentsUploadSection';
import { useDocumentsContextData } from 'pages/DocumentsPage/context/DocumentsPageContextProvider';
import { useMemo, useState } from 'react';
import { PageHelpSection } from 'components/PageHelpSection/PageHelpSection';
import { LoadingMenuItem } from 'pages/Dashboard/components/LoadingMenuItem/LoadingMenuItem';
import { assertUnreachable } from 'utils/assertUnreachable';
import { DropdownMenuSecondary } from 'components/DropdownMenu/DropdownMenuSecondary';

type Props = {
  subsection: SubsectionType;
};

export const DocumentsPageAllDocs = ({ subsection }: Props) => {
  const {
    summary,
    downloadReport,
    sectionType: section,
    helpSteps,
  } = useDocumentsContextData();
  const [isExporting, setIsExporting] = useState(false);
  const downloadSectionName = useMemo(
    () => section.slice(0, section.length - 1),
    [section],
  );

  const exportFile = async (type: 'summary' | 'logs') => {
    setIsExporting(true);

    const url =
      type === 'summary'
        ? `v1/${downloadSectionName}Documents/mapping/summary/file`
        : `v1/${downloadSectionName}Documents/mapping/documentExportLogs/file`;

    const fileName =
      type === 'summary' ? `${section} documents` : `${section} document logs`;

    try {
      await downloadReport({ url, fileName });
    } finally {
      setIsExporting(false);
    }
  };

  if (!subsection.includes(section)) return null;

  return (
    <div className="documents-page_all">
      <div className="documents-page_all-files">
        <h3> Upload and map {section} documents</h3>
        <div className="documents-page_all-content">
          <div className="documents-page_upload-section">
            <DocumentsUploadSection
              title={`1. Upload ${section} documents`}
              sectionType={section}
            />
            <div className="title flex-align-center">
              <h4>Previously uploaded {section} documents</h4>
              {(() => {
                switch (section) {
                  case SectionType.Claims:
                    return (
                      <ApButtonCasual
                        onClick={() => exportFile('summary')}
                        disabled={!summary.totalFiles}
                      >
                        <ApIcon iconName="save_alt" /> Export documents file
                      </ApButtonCasual>
                    );
                  case SectionType.Contracts:
                    return isExporting ? (
                      <div className="documents-page__export-button-loading">
                        <ApLoaderDefault
                          loaderSize="sm"
                          className="loading-menu-item__animation"
                        />
                        <div>Generating Excel file</div>
                      </div>
                    ) : (
                      <DropdownMenuSecondary
                        label="Export"
                        disabled={!summary.totalFiles || isExporting}
                        iconName="save_alt"
                        thinShape
                        disableCloseOnSubmenuClick={isExporting}
                        buttonClassname="documents-page__export-button"
                      >
                        <ApMenuItem onClick={() => exportFile('summary')}>
                          Export mapping file
                        </ApMenuItem>
                        <ApMenuItem onClick={() => exportFile('logs')}>
                          Export documents log
                        </ApMenuItem>
                      </DropdownMenuSecondary>
                    );
                  default:
                    return assertUnreachable(section);
                }
              })()}
            </div>
            <DocumentsPageUploadedFiles summary={summary} />
          </div>
          <div className="documents-page_upload-section">
            <DocumentsUploadSection
              mappingSection
              title={`2. Map ${section} documents`}
              sectionType={section}
            />
            <div className="download-template">
              or{' '}
              <ApButtonLink
                onClick={() =>
                  fileInfo.downloadTemplate(`Documents-${section}-template.xlsx`)
                }
              >
                Download template
              </ApButtonLink>
            </div>
          </div>
        </div>
      </div>
      <PageHelpSection steps={helpSteps} title="How to add new documents?" />
    </div>
  );
};
