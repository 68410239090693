import { ColDef } from 'ag-grid-community';
import { ContractRow } from 'api/endpoints';
import { ExhibitTable } from 'components/ExhibitsExport/ExhibitTable';
import { SvgIcon } from 'components/UIComponents/SvgIcon';
import { formatCurrency } from 'utils/formatNumber';
import './ExhibitContractsTable.css';

const AMOUNT_ALLOWED_BY_COURT = 100;

interface Props {
  rows: ContractRow[];
  loading?: boolean;
  onSelectedChange: (items: ContractRow[]) => any;
}

export const ExhibitContractsTable = ({
  rows,
  loading,
  onSelectedChange,
}: Props) => {
  const showWarning = rows.length > AMOUNT_ALLOWED_BY_COURT;

  return (
    <ExhibitTable
      rows={rows}
      loading={loading}
      title={`${rows?.length} Contracts to be included in the exhibit`}
      warningMessage={showWarning ? <WarningMessage /> : undefined}
      columnDefs={COLUMNS_DEF}
      onSelectedChange={onSelectedChange}
    />
  );
};

const WarningMessage = () => (
  <div className="exhibit-contracts-amount-warning">
    <SvgIcon iconName="alert_amber" width={15} height={11} />
    The number on this exhibit may exceed the amount allowed by the court
  </div>
);

export const COLUMNS_DEF: ColDef[] = [
  {
    field: 'active',
    headerName: '',
    headerCheckboxSelection: true,
    checkboxSelection: true,
    showDisabledCheckboxes: true,
    width: 10,
  },
  {
    field: 'referenceNumber',
    headerName: 'Ref',
    width: 150,
    cellRenderer: (params: { value: string }) => `#${params.value}`,
  },
  {
    field: 'counterpartyName',
    headerName: 'Counterparty',
    flex: 3,
    cellClass: 'ag-exhibit-bold-font',
  },
  {
    field: 'primaryDebtor',
    headerName: 'Debtor',
    flex: 3,
  },
  {
    field: 'negotiatedCureAmount',
    headerName: 'Cure amount',
    width: 150,
    type: 'rightAligned',
    cellClass: 'ag-right-aligned-cell',
    cellRenderer: (params: { value: number }) => formatCurrency(params.value),
  },
];
