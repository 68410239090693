import { ClaimRow } from 'api/endpoints';
import './SurvivingClaimNoSelect.css';

import { ApIcon } from '@alixpartners/ui-components';
import { Combobox } from '@headlessui/react';
import classNames from 'classnames';
import { useClaimsSearchByRef } from 'hooks/useClaimsSearchByRef';
import { memo, useCallback, useState } from 'react';
import {
  getInvertedUniqueItems,
  joinCommaSeparated,
  splitCommaSeparated,
} from 'utils/commaSeparatedText';
import { formatCurrency } from 'utils/formatNumber';

export type SurvivingClaimNoSelectProps = {
  value?: string;
  disabled?: boolean;
  onChange: (value: string) => void;
};

export function SurvivingClaimNoSelect(props: SurvivingClaimNoSelectProps) {
  const { value, disabled, onChange } = props;

  const [inputText, setInputText] = useState(value ?? '');

  const [searchText, setSearchText] = useState('');
  const suggestionsResult = useClaimsSearchByRef(searchText, { limit: 80 });

  const handleTextInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newInputText = event.target.value;
      setInputText(newInputText);
      onChange(newInputText);

      const lastValue = newInputText.split(',').at(-1)?.trim() ?? '';
      setSearchText(lastValue);
    },
    [onChange],
  );

  const handleValueSelected = (newValue: string) => {
    const valueItems = splitCommaSeparated(inputText);

    valueItems.pop(); // remove searched value
    valueItems.push(newValue);

    const uniqValues = getInvertedUniqueItems(valueItems);
    const text = joinCommaSeparated(uniqValues);
    setInputText(text);

    onChange(text);
  };

  return (
    <div className="survivingclaimnoselect">
      <Combobox
        disabled={disabled}
        value={value}
        nullable
        onChange={handleValueSelected}
      >
        <div className="survivingclaimnoselect__input">
          <Combobox.Input
            className={classNames('scp-input', 'survivingclaimnoselect__textinput')}
            placeholder={
              disabled ? undefined : 'Enter claim IDs separated by commas'
            }
            onChange={handleTextInputChange}
          />
          <ApIcon
            iconSize={16}
            className={classNames('survivingclaimnoselect__textinput__icon', {
              'utils-none': disabled,
            })}
            iconName="search"
          />
        </div>

        <ComboboxOptions suggestionsResult={suggestionsResult} />
      </Combobox>
    </div>
  );
}

const ComboboxOptions = (props: {
  suggestionsResult: ReturnType<typeof useClaimsSearchByRef>;
}) => {
  const { suggestionsResult } = props;
  const suggestionsAvailable =
    suggestionsResult.loading || !!suggestionsResult.data?.length;

  return (
    <Combobox.Options
      className={classNames('survivingclaimnoselect__options', 'thin-scrollbar', {
        'utils-none': !suggestionsAvailable,
      })}
    >
      {suggestionsResult.loading ? (
        <ComboboxLoadingItem />
      ) : (
        <ComboboxSuggestionList claims={suggestionsResult.data} />
      )}
    </Combobox.Options>
  );
};

const ComboboxSuggestionList = memo((props: { claims?: ClaimRow[] }) => {
  const { claims } = props;

  return (
    <>
      {claims?.map((claim) => {
        return (
          <Combobox.Option
            className={classNames('survivingclaimnoselect__option')}
            key={claim.referenceNumber}
            value={claim.referenceNumber}
          >
            <span>#{claim.referenceNumber}</span>
            <span>{claim.counterpartyName}</span>
            <span>{formatCurrency(claim.currentTotal)}</span>
          </Combobox.Option>
        );
      })}
    </>
  );
});

const ComboboxLoadingItem = () => {
  return (
    <Combobox.Option
      className={classNames('survivingclaimnoselect__option')}
      disabled
      value={''}
    >
      <span></span>
      <span>loading ....</span>
      <span></span>
    </Combobox.Option>
  );
};
