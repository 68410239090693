import { ColDef } from 'ag-grid-community';
import { ContractRow, GridColumn } from 'api/endpoints';

import classNames from 'classnames';
import { PinCell } from '../../components/PinCell';
import { SectionType, SubsectionType } from '../../types';

import AgSetFloatingFilter from '../../components/AgSetFloatingFilter';

import { DebtorNameCell } from 'pages/ClaimsContractsToolPage/components/DebtorNameCell';
import { MatchCodeNameCell } from 'pages/ClaimsContractsToolPage/components/MatchCodeNameCell';
import { CheckboxHeader } from '../../components/CheckboxHeader';
import { ContractCounterpartyNameCell } from '../../components/ContractCounterpartyNameCell';
import { PinHeader } from '../../components/PinHeader';
import {
  ACTION_STATUS_COLUMN,
  CONTRACTS_CONSTANT_COLUMNS,
  COUNTERPARTY_NAME_COLUMN,
  DATA_TYPE_MAP,
  DEBTOR_COLUMN,
  DO_NOT_DISPLAY_COLUMNS,
  FORMATTING_CURRENCY,
  MATCH_CODE_COLUMN,
  PIN_COLUMN,
  REFERENCE_NUMBER_COLUMN,
} from '../../const';
import { getValueFormatterFn } from '../../grid/ContractsGrid/ContractsGrid.utils';

import { getTooltipValueGetterFn } from '../columnUtils';
import { MatchingColumnDefsCallbacks } from '../useMatching';

export interface ContractsColumnDefsCallbacks extends MatchingColumnDefsCallbacks {
  refocusSelection: () => unknown;
  onCheckboxChange: (checked: boolean) => unknown;
}

const CHECKBOX_COLUMN_DEFITION: ColDef = {
  checkboxSelection: true,
  // field is absolutely neccessary because
  // otherwise ag-grid will assign "0" string to the field
  // and sometimes "1" string to the field
  // this will cause infinite update loops in useGridState
  field: 'checkbox',
  width: 60,
  maxWidth: 60,
  resizable: false,
  headerClass: 'checkbox',
  // https://stackoverflow.com/questions/57890959/header-checkbox-selection-doesnt-work-when-using-server-side-data-source
  // headerCheckboxSelection is not supported in server side model. It has to be
  // implemented manually
  // headerCheckboxSelection: true,
  headerComponent: CheckboxHeader,
  suppressColumnsToolPanel: true,
  menuTabs: [],
};
const PIN_COLUMN_DEFINITION: ColDef = {
  field: PIN_COLUMN,
  width: 50,
  maxWidth: 50,
  resizable: false,
  suppressColumnsToolPanel: true,
  menuTabs: [],
  headerComponent: PinHeader,
  cellRendererSelector: () => ({
    component: PinCell,
  }),
};

export function contractsStaticColumnDef(
  section: SectionType,
  subsection: SubsectionType,
  selectAllCheckbox: boolean,
  callbacks: ContractsColumnDefsCallbacks,
): ColDef<ContractRow>[] {
  const checkboxColumnDefinition = {
    ...CHECKBOX_COLUMN_DEFITION,
    headerComponentParams: {
      checked: selectAllCheckbox,
      onChange: callbacks.onCheckboxChange,
      section,
      subsection,
    },
  };

  switch (section) {
    case SectionType.Contracts:
      return [checkboxColumnDefinition, PIN_COLUMN_DEFINITION];
  }
  return [];
}

export function contractsGridColumnMapper(
  section: SectionType,
  subsection: SubsectionType,
  callbacks: ContractsColumnDefsCallbacks,
) {
  return (row: GridColumn): ColDef<ContractRow> | undefined => {
    if (DO_NOT_DISPLAY_COLUMNS.includes(row.propertyName)) return;

    // custom components for the given cell
    let { cellRenderer, cellRendererParams } = getCellRenderer(
      row,
      section,
      subsection,
      callbacks,
    );

    var { filter, filterParams } = getFilter(row);

    const valueFormatter = getValueFormatterFn(row.propertyName, row.dataType);

    const result: ColDef<ContractRow> = {
      field: row.propertyName,
      headerClass: row.propertyName,
      floatingFilterComponent:
        filter === 'agSetColumnFilter' ? AgSetFloatingFilter : undefined,
      filter,
      sortable: true,
      cellClass: classNames(row.propertyName, {
        currency: row.formatting === FORMATTING_CURRENCY,
      }),
      filterParams,
      initialWidth: getColumnWidth(row),
      unSortIcon: true,
      menuTabs: [],
      cellRendererParams,
      lockVisible: CONTRACTS_CONSTANT_COLUMNS.includes(row.propertyName),
      suppressMovable: CONTRACTS_CONSTANT_COLUMNS.includes(row.propertyName),
      headerValueGetter: () => row.displayName,
      valueFormatter,
      tooltipValueGetter: getTooltipValueGetterFn(row),
      cellRendererSelector: cellRenderer
        ? () => ({ component: cellRenderer })
        : undefined,
    };

    return result;
  };
}

const getFilter = (row: GridColumn) => {
  const filter: string | undefined = DATA_TYPE_MAP[row.dataType];

  const filterParams: any = {
    applyMiniFilterWhileTyping: true,
  };
  if (row.dataType === 'bool') {
    filterParams.values = [true, false];
  }
  if (row.dataType === 'enum') {
    filterParams.values = row.allowedValues;
  }
  if (['date', 'datetime'].includes(row.dataType)) {
    filterParams.filterOptions = [
      'equals',
      'greaterThan',
      'lessThan',
      'notEqual',
      'inRange',
    ];
  }
  if (['int', 'decimal'].includes(row.dataType)) {
    filterParams.filterOptions = [
      'equals',
      'notEqual',
      'lessThan',
      'lessThanOrEqual',
      'greaterThan',
      'greaterThanOrEqual',
      'inRange',
    ];
  }
  if (row.dataType === 'string') {
    filterParams.filterOptions = [
      'contains',
      'notContains',
      'equals',
      'notEqual',
      'startsWith',
      'endsWith',
    ];
  }
  return { filter, filterParams };
};

const getCellRenderer = (
  row: GridColumn,
  section: SectionType,
  subsection: SubsectionType,
  callbacks: ContractsColumnDefsCallbacks,
): {
  cellRenderer?: (props: any) => JSX.Element | string;
  cellRendererParams?: {};
} => {
  if (row.propertyName === COUNTERPARTY_NAME_COLUMN) {
    return {
      cellRenderer: ContractCounterpartyNameCell,
      cellRendererParams: {
        section,
        subsection,
      },
    };
  }

  if (row.propertyName === DEBTOR_COLUMN) {
    return {
      cellRenderer: DebtorNameCell,
      cellRendererParams: {
        section,
        subsection,
      },
    };
  }

  if (row.propertyName === MATCH_CODE_COLUMN) {
    return {
      cellRenderer: MatchCodeNameCell,
      cellRendererParams: {
        section,
        subsection,
        onUpdateMatchCode: callbacks.onUpdateMatchCode,
        onFetchNextMatchCode: callbacks.onFetchNextMatchCode,
        onLostFocus: callbacks.refocusSelection,
      },
    };
  }

  return {};
};

const getColumnWidth = (col: GridColumn): number | undefined => {
  if (col.propertyName === REFERENCE_NUMBER_COLUMN) {
    return 150;
  }

  if (col.propertyName === COUNTERPARTY_NAME_COLUMN) {
    return 320;
  }

  if (col.propertyName === ACTION_STATUS_COLUMN) {
    return 242;
  }

  if (col.dataType === 'enum') {
    return 150;
  }

  if (['int', 'decimal'].includes(col.dataType)) {
    return 120;
  }

  if (col.dataType === 'bool') {
    return 120;
  }

  if (['date', 'datetime'].includes(col.dataType)) {
    return 120;
  }

  return 200;
};
