import { ClaimValue } from 'api/endpoints/settlements';
import classnames from 'classnames';
import { Cell, ClaimsDocumentsTable, Row } from 'components/ClaimsDocumentsTable';
import './SettlementAmountGrid.css';

interface Props {
  claims: ClaimValue[] | undefined;
  total: ClaimValue | undefined;
}

export const SettlementAmountGrid = ({ claims, total }: Props) => {
  return (
    //todo: create a new table instead of ClaimsDocumentsTable (or no??????)
    <ClaimsDocumentsTable
      className="settlement-amount-grid"
      // emptyListMessage={<span>No attachments for this Settlement</span>}
      headerItems={[
        'Claim number / Debtor Name',
        'Amounts',
        'Secured',
        'Admin',
        'Priority',
        'Unsecured',
        'Total',
      ]}
    >
      <ClaimsDocumentsRows claims={claims} total={total} />
    </ClaimsDocumentsTable>
  );
};

const EMPTY_CLAIM: ClaimValue = {} as ClaimValue;

const ClaimsDocumentsRows = (props: Pick<Props, 'claims' | 'total'>) => {
  const { claims, total } = props;

  if (!claims) return <ValueRow key={0} claim={EMPTY_CLAIM} />;

  return (
    <>
      {claims.map((r) => (
        <ValueRow key={r.claimId} claim={r} />
      ))}
      <ValueRow key="total_row" claim={total} isTotal />
    </>
  );
};

const ValueRow = (props: { claim: ClaimValue | undefined; isTotal?: boolean }) => {
  const { claim, isTotal } = props;
  if (!claim)
    return (
      <Row
        className={classnames('settlement-amount-grid__row', {
          'settlement-total-row': isTotal,
        })}
      ></Row>
    );

  return (
    <Row
      className={classnames('settlement-amount-grid__row', {
        'settlement-total-row': isTotal,
      })}
    >
      <Cell style={{ width: '25%' }}>{claim.name}</Cell>

      <CellDoubleValue value1="Current:" value2="Proposed:" />
      <CellDoubleValue
        numberValue
        value1={claim.currentSecured}
        value2={claim.proposedSecured}
      />
      <CellDoubleValue
        numberValue
        value1={claim.currentAdministrative}
        value2={claim.proposedAdministrative}
      />
      <CellDoubleValue
        numberValue
        value1={claim.currentPriority}
        value2={claim.proposedPriority}
      />
      <CellDoubleValue
        numberValue
        value1={claim.currentUnsecured}
        value2={claim.proposedUnsecured}
      />
      <CellDoubleValue
        numberValue
        value1={claim.currentTotal}
        value2={claim.proposedTotal}
      />
    </Row>
  );
};

const CellDoubleValue = (props: {
  value1: string | number;
  value2: string | number;
  numberValue?: boolean;
}) => {
  return (
    <Cell>
      <div
        className={classnames('settlement-amount-grid__cell-double-value', {
          'settlement-amount-grid__number-cell': props.numberValue,
        })}
      >
        <span> {props.value1} </span>
        <span> {props.value2} </span>
      </div>
    </Cell>
  );
};
