import { ApModal, ApButtonIcon} from '@alixpartners/ui-components';
import { NavBarContainer } from 'components/NavBar';

import './PreviewModal.css';

interface Props {
  isOpen?: boolean;
  onClose: () => void;
  previewData: {
    newCompanyLogo: ArrayBuffer | undefined,
    companyName: string | undefined,
    logoFileName: string | undefined
  }
}

export const PreviewModal = ({ isOpen, onClose, previewData }: Props) => {
  return (
    <ApModal
      isDismissible={true}
      isFluid={false}
      isOpen={isOpen}
      zIndex={998}
      hasClosed={onClose}
      className='preview-modal__container'
    >
      <ApButtonIcon
        autoFocus={false}
        disabled={false}
        hoverColor="#5CB335"
        iconName="close"
        onClick={onClose}
        className='button-close'
        theme='dark'
      />

      <div className="preview-modal__wrapper">
        <div className="preview-modal__nav-bar-container">
          <NavBarContainer previewData={previewData} />
        </div>
        <div className="preview-modal__white-space">
          <div className="preview-modal__black-object" />
        </div>
      </div>
    </ApModal>
  );
};
