import { ClaimEditValues } from 'api/endpoints';
import classNames from 'classnames';

import { ActionExhibitTypeId } from 'api/endpoints/actions-select-options';
import { SCPInput } from 'components/SingleClaim/SCPInput';
import { SwitchButton } from 'components/UIComponents/SwitchButton';
import { useAppSelector } from 'hooks/reducerHooks';
import upperFirst from 'lodash/upperFirst';
import { useMemo } from 'react';
import { formatCurrency } from 'utils/formatNumber';
import { useAppUserType } from 'utils/hooks/useAppUserType';
import { EditFieldFunc } from '../../hook/useEditClaim';
import { SCMatrix } from '../../types';
import { CategorySelect } from '../CategorySelect';
import './SingleClaimMatrix.css';

export interface Props {
  matrix: SCMatrix;
  claim?: ClaimEditValues;
  title?: string;
  editField: EditFieldFunc;
  isEditable: (row: string, column?: string) => boolean;
}

export const SingleClaimMatrix = ({
  matrix,
  claim,
  title,
  editField,
  isEditable,
}: Props) => {
  const { isClient } = useAppUserType();
  const { actionTypeId } = useAppSelector(
    (state) => state.claimActionNameSelection.claimActionInfo,
  );

  const { disableAmounts } = useMemo(
    () => ({
      disableAmounts: [
        ActionExhibitTypeId.Allow,
        ActionExhibitTypeId.Expunge,
        ActionExhibitTypeId.ExpungeOther,
        ActionExhibitTypeId.ExpungeSettlement,
      ].includes(actionTypeId!),
    }),
    [actionTypeId],
  );

  return (
    <div className="single-claim-matrix">
      <table cellSpacing={0}>
        <thead>
          <tr className="header">
            {title ? (
              <td
                className={classNames('header-title section__title')}
                key={`td-title`}
              >
                <span>{title}</span>
              </td>
            ) : (
              <td key={'td0'} />
            )}
            {matrix.columns.map((column, index) => {
              if (column.type === 'empty') {
                return <td className={'header-label empty'} key={`td${index}`} />;
              }
              return (
                <td
                  className={classNames('header-label', column.field)}
                  key={`td${index}`}
                >
                  <span>{column.label}</span>
                </td>
              );
            })}
          </tr>
        </thead>
        <tbody>
          <tr></tr>
          {matrix.rows.map((row, index) => {
            if (row?.type === 'empty') {
              return <tr className="row empty" key={`tr${index}`} />;
            }
            return (
              <tr className="row" key={`tr${index}`}>
                <td className="row-label">
                  <span>{row.label}:</span>
                </td>
                {matrix.columns.map((column, index) => {
                  if (column.type === 'empty')
                    return <td className="empty" key={`td${index}`} />;
                  const field = `${row.field}${upperFirst(column.field)}`;
                  let value = claim ? (claim as any)[field] : undefined;

                  // AlixPartners amounts have to be editable irrespective of actionType
                  let editable = isEditable(row.field, column.field);

                  // todo: move the logic bellow to isEditable method
                  if (row.field !== 'alixPartners' && disableAmounts) {
                    editable = false;
                  }

                  if (row.field === 'alixPartners' && isClient) {
                    editable = false;
                  }

                  if (column.type === 'number' && column.formatting === 'currency') {
                    value = formatCurrency(value);
                  }

                  // if editable use SCPInput
                  if (row.hideBoolean && column.type === 'bool') {
                    return null;
                  }

                  return (
                    <td
                      className={classNames('row-value', { editable }, column.field)}
                      key={`td${index}`}
                    >
                      {column.type === 'number' && (
                        <span>
                          <SCPInput
                            value={value}
                            onBlur={(e) => {
                              var cleanedValue = e.target.value || '0';
                              if (isNaN(Number(cleanedValue))) {
                                cleanedValue = cleanedValue.replace(/[^0-9.]/g, '');
                                if (cleanedValue.split('.').length > 2)
                                  cleanedValue = cleanedValue.replace(/\.+$/, '');
                              }
                              editField(field, cleanedValue);
                            }}
                            disabled={!editable}
                            amountField={column.formatting === 'currency'}
                          />
                        </span>
                      )}
                      {column.type === 'bool' && (
                        <span className="row-toggle-switch">
                          <SwitchButton
                            value={value}
                            onChange={(checked) => editField(field, checked)}
                            disabled={!editable}
                          />
                        </span>
                      )}
                      {column.type === 'category' && column.category && (
                        <CategorySelect
                          {...column.category}
                          type={column.category.type}
                          name={field}
                          disabled={!editable}
                          editField={editField}
                          claim={claim}
                        />
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
