import { ApAppBar, ApMenuItem } from '@alixpartners/ui-components';
import { INotification } from '@alixpartners/ui-components/dist/types/components/ApNotifications/NotificationItem';
import { ApAuthenticationContextConsumer } from '@alixpartners/ui-utils';
import { BasicClientInfo } from 'api/endpoints';
import { URL_PATH } from 'appUrls';
import { useAppSelector } from 'hooks/reducerHooks';
import { useBookmarksData } from 'pages/BookmarksPage/hooks/useBookmarksData';
import { AddBookmark } from 'pages/BookmarksPage/sections/AddBookmark';
import { SectionType } from 'pages/ClaimsContractsToolPage/types';
import { useCallback, useMemo } from 'react';
import { Location, useLocation, useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { useAppInitialDataContext } from 'utils/AppInitializer';
import { useAppUserType } from 'utils/hooks/useAppUserType';
import { clearAll } from 'utils/local-storage';
import { NavIconButton } from './components/NavIconButton';
import { NavIconMenu } from './components/NavIconMenu';
import { NavItemSeparator } from './components/NavItemSeparator';
import { NavLinkTab } from './components/NavLinkTab';
import './NavBar.css';
import DOMPurify from 'dompurify';

interface PreviewDataProps {
  newCompanyLogo?: ArrayBuffer | undefined;
  companyName?: string | undefined;
  logoFileName: string | undefined;
}

export const NavBarContainer = (props: {
  hideHeaderMenu?: boolean;
  previewData?: PreviewDataProps | undefined;
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { data } = useAppInitialDataContext();
  const { basicInfo } = data;
  const { clientInfo } = basicInfo;

  return (
    <ApAuthenticationContextConsumer>
      {({ logout }) => (
        <NavBar
          clientInfo={clientInfo}
          notification={undefined}
          hideHeaderMenu={props.hideHeaderMenu}
          logout={logout}
          navigate={navigate}
          previewData={props.previewData}
          location={location}
        />
      )}
    </ApAuthenticationContextConsumer>
  );
};

export const NavBar = (props: {
  clientInfo: BasicClientInfo;
  notification?: INotification[];
  hideHeaderMenu?: boolean;
  navigate: (url: string) => void;
  logout?(): void;
  previewData: PreviewDataProps | undefined;
  location: Location;
}) => {
  const {
    clientInfo,
    hideHeaderMenu,
    notification,
    navigate,
    logout: logoutProp,
    previewData,
    location,
  } = props;

  const logout = useCallback(() => {
    logoutProp?.();
    clearAll();
  }, [logoutProp]);

  return (
    <ApAppBar
      className="header-navbar"
      backgroundColor={'var(--client-secondary-brand-color)'}
      position="static"
      isFluid
      thin
      left={() => (
        <NavBarLeftContent clientInfo={clientInfo} previewData={previewData} />
      )}
      right={() =>
        !hideHeaderMenu && (
          <NavBarRightContent
            {...{
              locationPath: location.pathname,
              notification,
              navigate,
              logout,
              locationSearch: location.search,
            }}
          />
        )
      }
    />
  );
};

const NavBarLeftContent = (props: {
  clientInfo: BasicClientInfo;
  previewData?: PreviewDataProps;
}) => {
  const { clientInfo, previewData } = props;
  const { clientCompanyName, logoFileName, logoContent } = clientInfo;
  const logoImage = useMemo(
    () => logoContent && URL.createObjectURL(new Blob([logoContent])),
    [logoContent],
  );

  let previewDataLogoFileName = previewData?.logoFileName;
  if (!previewData?.logoFileName?.split('logo/')[1]) {
    previewDataLogoFileName = '';
  }

  let clientInfoLogoFileName = logoFileName;
  if (!logoFileName?.split('logo/')[1]) {
    clientInfoLogoFileName = '';
  }

  const previewLogoImage = useMemo(
    () =>
      previewData?.newCompanyLogo
        ? URL.createObjectURL(new Blob([previewData.newCompanyLogo]))
        : previewDataLogoFileName,
    [previewData?.newCompanyLogo, previewDataLogoFileName],
  );

  // Logic for displaying client name
  const clientName = previewData?.companyName || clientCompanyName;

  // Logic for displaying client logo
  const imageSrc = previewData
    ? previewLogoImage
    : clientInfoLogoFileName || logoImage;

  return (
    <div className="header-navbar_logo">
      <Link to={'/module-selector'} className="header-navbar_logolink">
        <div className="header-navbar_main-logo" />
        <NavItemSeparator color="var(--client-primary-brand-color)" />
        <div className="header-avbar_appname">Chapter 11 Platform</div>
      </Link>

      {clientName && (
        <>
          <div className="header-navbar_client">
            <NavItemSeparator color="var(--ap-color-ui-white)" />
          </div>

          <div className="header-navbar_clientname">{clientName}</div>
        </>
      )}

      {imageSrc && (
        <>
          <div className="header-navbar_client">
            <NavItemSeparator color="var(--ap-color-ui-white)" />
          </div>

          <div className="header-navbar_image_wrapper">
            <img
              src={DOMPurify.sanitize(imageSrc)}
              alt="client-logo"
              className="header-navbar_image"
            />
          </div>
        </>
      )}
    </div>
  );
};

const NavBarRightContent = (prop: {
  locationPath?: string;
  notification?: INotification[];
  navigate?: (url: string) => void;
  logout?: () => void;
  locationSearch?: string;
}) => {
  const { locationPath, navigate, logout, locationSearch } = prop;

  const isActive = getIsActivePageFn(locationPath);
  const navigateTo = (url: string) => () => navigate?.(url);
  const { isApUser } = useAppUserType();
  const { bookmarks = [] } = useAppSelector((s) => s.selectable.data);
  const section = useMemo(
    () =>
      locationSearch?.includes('section=Claims')
        ? SectionType.Claims
        : locationSearch?.includes('section=Contracts')
        ? SectionType.Contracts
        : SectionType.NONE,
    [locationSearch],
  );

  const { openModal, setOpenModal, enableAddBookmark } = useBookmarksData(section);

  return (
    <div className="header-navbar_menu">
      <NavLinkTab
        label="Module Selector"
        active={isActive('/module-selector')}
        onClick={navigateTo('/module-selector')}
      />

      <NavItemSeparator color="#4B4B4B" />

      {/* todo: uncomment this when notification is implemented */}
      {/* <NavNotificationButton tooltip="Notifications" notification={notification} /> */}

      <NavIconButton
        active={isActive(URL_PATH.HELP)}
        onClick={navigateTo(URL_PATH.HELP)}
        tooltip="Help"
        iconName={'baseline_help_outline'}
      />

      <NavIconButton
        active={isActive(URL_PATH.CONTACT_US)}
        onClick={navigateTo(URL_PATH.CONTACT_US)}
        tooltip="Contact us"
        iconName={'baseline_email_outline'}
      />

      <NavIconMenu
        active={isActive('/bookmarks')}
        tooltip="Bookmarks"
        iconName={'outlined_bookmark_border'}
      >
        <ApMenuItem disabled={!enableAddBookmark} onClick={() => setOpenModal(true)}>
          Add bookmark
        </ApMenuItem>
        <ApMenuItem onClick={navigateTo('/bookmarks')} disabled={!bookmarks.length}>
          See all bookmarks ({bookmarks.length})
        </ApMenuItem>
      </NavIconMenu>

      <NavIconButton
        active={isActive('/data-upload')}
        onClick={navigateTo('/data-upload')}
        tooltip="Data uploading"
        iconName={'cloud_upload_outlined'}
      />

      <NavIconMenu
        active={isActive(['/settings', '/options'])}
        tooltip="General settings"
        iconName={'settings_outline'}
      >
        {isApUser ? (
          <ApMenuItem onClick={navigateTo('/settings')}>Settings</ApMenuItem>
        ) : (
          <></>
        )}
        <ApMenuItem onClick={() => logout?.()}>Log out</ApMenuItem>
      </NavIconMenu>
      {openModal && (
        <AddBookmark
          openModal={openModal}
          handleClose={() => setOpenModal(false)}
          section={section}
        />
      )}
    </div>
  );
};

const getIsActivePageFn =
  (locationPage: string = '') =>
  (url: string | string[]) => {
    if (typeof url === 'string') {
      return locationPage === url;
    }

    return url.includes(locationPage);
  };
