import { settings } from 'api/endpoints';
import { useEffect, useMemo, useRef, useState } from 'react';
import { error } from 'utils/alert';
import { useAppInitialDataContext } from 'utils/AppInitializer/AppInitalDataProvider';
import { fetchUrlFileToBuffer, uploadLogo } from 'utils/uploader';

import { ApTextInput } from '@alixpartners/ui-components';
import { ButtonMainLoader } from 'components/UIComponents/ButtonMainLoader';
import { ApButtonCasual } from 'components/UIComponents/Buttons/ApButtonCasual';
import { Panel } from 'components/UIComponents/EntityTable';
import { SettingsContentPanel } from 'pages/SettingsPage/components/SettingsContentPanel';
import { WizardFieldLabel } from 'pages/SetupWizardPage/component';
import AvatarEditor from 'react-avatar-editor';
import { urlExtractFileName } from 'utils/file';
import './ClientNameLogo.css';
import { LogoSelector } from './components/LogoSelector';
import { PreviewModal } from './PreviewModal';

export function ClientNameLogo() {
  const editor = useRef<AvatarEditor>(null);
  const { data } = useAppInitialDataContext();
  const { clientInfo } = data.basicInfo;

  const [saving, setSaving] = useState<boolean>(false);
  const [showPreviewModal, setShowPreviewModal] = useState<boolean>(false);
  const [clientCompanyName, setClientCompanyName] = useState<string>(
    clientInfo.clientCompanyName,
  );

  const [initialLogo, setInitialLogo] = useState<ArrayBuffer>();
  const [previewLogo, setPreviewLogo] = useState<ArrayBuffer>();

  const [newLogoFileName, setNewLogoFileName] = useState<string>();

  const logoImageUrl = useMemo(
    () => initialLogo && URL.createObjectURL(new Blob([initialLogo])),
    [initialLogo],
  );

  useEffect(() => {
    fetchUrlFileToBuffer(clientInfo.logoFileName).then((logo) =>
      setInitialLogo(logo),
    );
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getLogoFromEditor = async () => {
    if (!editor.current) return;

    // Strange, but we need to do this weird operations to get data from an image editor
    const dataUrl = editor.current.getImageScaledToCanvas().toDataURL();
    const res = await fetch(dataUrl);
    const buffer = await res.arrayBuffer();
    return buffer;
  };

  const saveClientInfo = async () => {
    setSaving(true);

    const clientInfoToSave = {
      ...clientInfo,
      primaryBrandColor: clientInfo.primaryBrandColor ?? '',
      secondaryBrandColor: clientInfo.secondaryBrandColor ?? '',
      clientCompanyName: clientCompanyName,
      logoFileName: '',
    };

    const logoContent = await getLogoFromEditor();
    if (logoContent) {
      const name = newLogoFileName || urlExtractFileName(clientInfo.logoFileName);
      const logoFileName = await uploadLogo({ name, logoContent });
      if (!logoFileName) {
        error('Failed to upload the logo');
      }
      clientInfoToSave.logoFileName = logoFileName ?? '';
    }

    await settings.postBasicInfo(clientInfoToSave);
    setSaving(false);

    await data.basicInfo.init();
  };

  const handlePreviewClick = async () => {
    const logo = await getLogoFromEditor();
    setPreviewLogo(logo);

    setShowPreviewModal(true);
  };

  const handleSetLogo = (content: ArrayBuffer, filename: string, type: string) => {
    setNewLogoFileName(filename);
    setInitialLogo(content);
  };

  const onLogoRemoved = () => {
    setNewLogoFileName(undefined);
    setInitialLogo(undefined);
  };

  return (
    <SettingsContentPanel>
      <section className="cilentnamelogo">
        <Panel title="Client Name">
          <WizardFieldLabel text="Client company name" />
          <ApTextInput
            id="setup-wizard-companyname"
            value={clientCompanyName}
            onChange={(e: React.FormEvent<any>) =>
              setClientCompanyName(e.currentTarget.value)
            }
          ></ApTextInput>
        </Panel>
        <Panel title="Logo">
          <LogoSelector
            logo={logoImageUrl}
            ref={editor}
            onLogoUploaded={handleSetLogo}
            onLogoRemoved={onLogoRemoved}
          />
        </Panel>
        <div className="client-name-logo__footer">
          <ApButtonCasual onClick={handlePreviewClick} disabled={saving}>
            Preview
          </ApButtonCasual>
          <ButtonMainLoader onClick={saveClientInfo} loading={saving}>
            Save Changes
          </ButtonMainLoader>
        </div>
      </section>
      <PreviewModal
        isOpen={showPreviewModal}
        onClose={() => setShowPreviewModal(false)}
        previewData={{
          newCompanyLogo: previewLogo,
          companyName: clientCompanyName,
          logoFileName: logoImageUrl,
        }}
      />
    </SettingsContentPanel>
  );
}
