import { ClaimRow } from 'api/endpoints';
import { dateISOtoISO8601 } from 'utils/formatDate';
import { formatCurrency } from 'utils/formatNumber';
import {
  ClaimItemFormatted,
  ClaimTableType,
  ClaimTallyItem,
  ClaimTallyItemFormatted, ContractItemFormatted, ContractRowFormatted,
} from './Counterparty.types';
import { ContractReviewerData } from '../../reducer/contractReviewerReducer';
import { filedClaimsHeaders } from './Counterparty.const';

export function formatClaimTallyItem(item: ClaimTallyItem): ClaimTallyItemFormatted {
  return {
    ...item,
    admin_formatted: formatCurrency(item.admin),
    priority_formatted: formatCurrency(item.priority),
    secured_formatted: formatCurrency(item.secured),
    count_formatted: formatCurrency(item.count),
    total_formatted: formatCurrency(item.total),
    unsecured_formatted: formatCurrency(item.unsecured),
  };
}

export function formatFiledClaimItem(item: ClaimRow): ClaimItemFormatted {
  return {
    ...item,
    clientTotal_formatted: formatCurrency(item.clientTotal),
    currentTotal_formatted: formatCurrency(item.currentTotal),
    proposedTotal_formatted: formatCurrency(item.proposedTotal),
    originalTotal_formatted: formatCurrency(item.originalTotal),
    counselTotal_formatted: formatCurrency(item.counselTotal),
    lowTotal_formatted: formatCurrency(item.lowTotal),
    highTotal_formatted: formatCurrency(item.highTotal),
    // reviewLevel_formatted: `Level ${item.reviewLevel}`,
    officialClaimDate_formatted: dateISOtoISO8601(item.officialClaimDate) ?? '-',
  };
}

export function formatScheduledClaimItem(
  item: ClaimRow,
): ClaimItemFormatted {
  return {
    ...item,
    currentTotal_formatted: formatCurrency(item.currentTotal),
    originalTotal_formatted: formatCurrency(item.originalTotal),
    proposedTotal_formatted: formatCurrency(item.proposedTotal),
    officialClaimDate_formatted: dateISOtoISO8601(item.officialClaimDate) ?? '-',
  };
}

function createCommaSeparatedFullNames(contractsReviewers: ContractReviewerData): string {
  const fullNames: string[] = contractsReviewers?.userReviewers.map((user) => `${user.firstName} ${user.lastName}`);
  return fullNames.join(', ');
}

export function formatContractItem(
  item: ContractRowFormatted,
): ContractItemFormatted {
  return {
    ...item,
    contractsReviewers: createCommaSeparatedFullNames(item.contractsReviewers),
    rejectionDamageLow_formatted: formatCurrency(item.rejectionDamageLow),
    rejectionDamagesHigh_formatted: formatCurrency(item.rejectionDamagesHigh),
  };
}

export const getClaimsHeaders = (claimTableType: ClaimTableType) => {
  const res = filedClaimsHeaders;
  if (claimTableType === ClaimTableType.FILED) res.proposedTotal_formatted = 'AlixPartners total';
  if (claimTableType === ClaimTableType.SCHEDULED) res.proposedTotal_formatted = 'Proposed total';
  return res;
};
