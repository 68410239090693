import { useCallback, useEffect, useMemo, useState } from 'react';

import { ColDef, GridOptions, ValueGetterParams } from 'ag-grid-community';
import { ClaimRow } from 'api/endpoints';
import useGraphql from 'api/graphql/useGraphql';
import { ExhibitTable } from 'components/ExhibitsExport/ExhibitTable';
import { SvgIcon } from 'components/UIComponents/SvgIcon';
import { useAppDispatch, useAppSelector } from 'hooks/reducerHooks';
import { setClaimsByActionName } from 'reducer/exhibits/exhibitReducer';
import { AgGridFilter } from 'utils/agGridHotchocolate/types';
import { ExhibitTemplates, getTemplateName } from '../../templates/exibitType';
import { getClaimsBy } from '../../utils/apiExhibitClaim';
import { getClaimNumber } from '../../utils/field';

import { ApIconInfoTooltip } from 'components/UIComponents/ApIconTooltip';
import './ClaimsTable.css';
import { formatCurrency } from 'utils/formatNumber';

const AMOUNT_ALLOWED_BY_COURT = 100;

export const ClaimsTable = ({
  actionSubstatus,
  getSelectedClaims,
}: {
  actionName: string;
  actionSubstatus?: number;
  getSelectedClaims: (claims: any) => any;
}) => {
  const dispatch = useAppDispatch();
  const [client] = useGraphql();
  const [gridLoading, setGridLoading] = useState(false);

  const { claims = [], actionNameId } = useAppSelector(
    (s) => s.exhibit.currentExhibit,
  );

  const loadGridRows = useCallback(async () => {
    if (!actionNameId) return;

    setGridLoading(true);
    const filterModel: AgGridFilter = {
      filterType: 'number',
      type: 'equals',
      filter: actionNameId,
    };
    const data = await getClaimsBy(client, 'actionNameId', filterModel);
    dispatch(setClaimsByActionName(data));
    setGridLoading(false);
  }, [actionNameId, client, dispatch]);

  const clenupGridRows = useCallback(async () => {
    dispatch(setClaimsByActionName([]));
  }, [dispatch]);

  const showWarning = claims.length > AMOUNT_ALLOWED_BY_COURT;

  useEffect(() => {
    if (!actionNameId) {
      clenupGridRows();
      return;
    }

    loadGridRows();
  }, [actionNameId, clenupGridRows, loadGridRows]);

  const columnDefs = useMemo(
    () => getColumnDefs(getTemplateName(actionSubstatus ?? 0)),
    [actionSubstatus],
  );

  return (
    <ExhibitTable
      rows={claims}
      loading={gridLoading}
      title={`${claims?.length} claims to be included in the exhibit`}
      warningMessage={showWarning ? <WarningMessage /> : undefined}
      columnDefs={columnDefs}
      onSelectedChange={getSelectedClaims}
      gridOptions={gridOptions}
    />
  );
};

const WarningMessage = () => (
  <div className="exhibit-contracts-amount-warning">
    <SvgIcon iconName="alert_amber" width={15} height={11} />
    The number on this exhibit may exceed the amount allowed by the court
  </div>
);

const EmptyScheduleNumber = () => (
  <span className="claim-schedule-number_novalue">
    <SvgIcon iconName="exclam_circle" width={18} height={16} fill="red" />
    no data
  </span>
);

const gridOptions: GridOptions<ClaimRow> = {
  rowClassRules: {
    'claims-table__row-error': ({ data }) => !getClaimNumber(data),
  },
};

const getColumnDefs = (template?: string): ColDef[] => {
  return [
    {
      field: 'active',
      headerName: '',
      headerCheckboxSelection: true,
      checkboxSelection: true,
      showDisabledCheckboxes: true,
      width: 10,
    },
    {
      // headerName: 'Claims number',
      headerComponent: () => (
        <span className="exhibit-contracts__grid-header__claims-number">
          Claims number
          <ApIconInfoTooltip
            message={'"Claim Schedule Number" will be used for all Schedule Claims'}
          />
        </span>
      ),
      width: 150,
      cellRenderer: (params: { value: string }) =>
        params.value ? `#${params.value}` : <EmptyScheduleNumber />,
      valueGetter: ({ data }: ValueGetterParams<ClaimRow>) => getClaimNumber(data),
    },
    {
      field: 'counterpartyName',
      headerName: 'Counterparty',
      flex: 3,
      cellClass: 'ag-exhibit-bold-font',
    },
    {
      field:
        template === ExhibitTemplates.ExpungeWithSurviving
          ? 'currentDebtor'
          : 'proposedDebtor',
      headerName:
        template === ExhibitTemplates.ExpungeWithSurviving
          ? 'Current debtor'
          : 'Proposed debtor',
      flex: 3,
    },
    {
      field: 'currentTotal',
      headerName: 'Current total',
      width: 150,
      type: 'rightAligned',
      cellClass: 'ag-right-aligned-cell',
      cellRenderer: (params: { value: number }) => formatCurrency(params.value),
    },
  ];
};
